import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Card } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CTA from "../components/cta";
import BlogFeed from "../components/blog-feed";
import Hero from "../components/hero";
import NeedAQuote from "../components/need-a-quote";
import Quote from "../components/quote";
import transformations from "../icons/design-refine.svg";
import implementations from "../icons/puzzle2.svg";
import Integrations from "../icons/integration.svg";
import management from "../icons/collaborative.svg";
import migrations from "../icons/migrate.svg";
import testing from "../icons/evaluating.svg";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import githubLogo from "../images/partners/github.png";
import githubWhiteLogo from "../images/partners/github-white.png";


const gitHubOptions = [{
  option: "migrations",
  logo: migrations,
  text: "Move your repository into GitHub from another service, or from GitHub to GitHub Enterprise.  729 can help you plan, structure and execute your migration needs.",
  alt: "Migrations icon"
},
  {
    option: "transformations",
    logo: transformations,
    text: "Transform your teams and projects and work within a more efficient workflow through agile methodologies.",
    alt: "Transformations icon"
  },
  {
    option: "implementations",
    logo: implementations,
    text: "Get the Git flow you need set up, allowing your multiple teams to coordinate and work within the same repository.",
    alt: "Implementations icon"
  },
  {
    option: "integrations",
    logo: Integrations,
    text: "Connect all of the tools you use to GitHub through a pre-built or custom integration built by 729 Solutions.",
    alt: "Integrations icon"
  },
  {
    option: "management",
    logo: management,
    text: "Manage access for users and teams more efficiently to protect your code and cloud resources.",
    alt: "Management icon"
  },
  {
    option: "testing",
    logo: testing,
    text: 'Catch bugs and launch your code with confidence using GitHub to review and test before you goes live.',
    alt: "Testing icon"
  }];


const Index = ({location}) => {

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Partnerships GitHub"
        description="Make the most of GitHub through migrations, implementations and customizations. Get efficient in your processes, code review and project security."
      />
      <Hero bgImage={patternHex}>
        <Hero.Inner>
          <Hero.Image
            src={githubLogo}
            srcAlt={githubWhiteLogo}
            alt="GitHub Octocat and wordmark"
          />
          <Hero.Content>
            <Hero.Title>
              729 Solutions provides end to end services around GitHub
            </Hero.Title>
            <Hero.Description>
              <p className="text-white text-center text-md-left">
                We help teams migrate and implement GitHub into their daily
                workflow using industry-standard best practices. For existing
                customers, we can optimize your development teams’ build and
                release processes. If you want to create a high-velocity
                development culture that creates and deploys quickly, contact
                729 to make the most of GitHub.
              </p>
            </Hero.Description>
            <Hero.CallToAction>
              <CTA
                href="/contact-us"
                text="Let's Talk About Your Project"
                className="btn btn-secondary-light"
              />
            </Hero.CallToAction>
          </Hero.Content>
        </Hero.Inner>
      </Hero>
      <section>
        <div className="container py-6">
          <div className="row mb-4">
            <div className="col">
              <h2>What type of GitHub work do you need?</h2>
            </div>
          </div>
          <div className="row">
            {gitHubOptions.map((gitHubOption) => {
              const { logo: Icon } = gitHubOption;
              return (
                <div className="col-lg-4">
                  <Card className="card custom-cards">
                    <Icon
                      width={175}
                      height={175}
                      className="card-img"
                      alt={gitHubOption.alt}
                    />
                    <Card.Body>
                      <AnchorLink
                        className="btn btn-primary"
                        to={`#${gitHubOption.option}`}
                      >
                        {gitHubOption.option}
                      </AnchorLink>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#2222221a" }}>
        <div className="container py-6">
          <div className="row py-5 px-4 ">
            {gitHubOptions.map(({ text, option }) => (
              <div className="col-lg-6 mb-4" id={option}>
                <h3>{option}</h3>
                <p>{text}</p>
              </div>
            ))}
          </div>
          <div className="row d-flex justify-content-center">
            <CTA
              href="/contact-us/"
              text="Get GitHub Help Now"
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col py-2">
              <Quote
                quote="A better product for our users and a more predictable development process for us internally."
                name="Don Jones"
                company="Venture Deal"
              />
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote dark />
      <BlogFeed containerClassname="py-6" />
    </Layout>
  );
};

export default Index;
